import { environments } from '@sunrun/ui-product-selector';

export const ACCESS_TOKEN = {
  [environments.STAGE]: 'kH1mEumO_XxsyzbSh395uMC3dfo2lzeZFe5Q3TNl88k',
  [environments.PRODUCTION]: '1yzw4RXVMgBMnoYGoe-xpSgYBtPWZSVkgF95wKS_zz8',
};

export const HOST = {
  [environments.STAGE]: 'preview.contentful.com',
  [environments.PRODUCTION]: 'cdn.contentful.com',
};

export const PRODUCT_SELECTOR_MAIN_ENTRY  = '2coXeTvsFPW704lPMqnfPl'
export const SPACE = '6sfmby2f4wb3';
