import React from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';

import Page from './Page';
import apiKeys from './constants/apiKeys';

// Initialize GA
ReactGA.initialize(apiKeys.GA);
if (typeof window !== 'undefined') {
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const tagManagerArgs = {
  gtmId: apiKeys.GTM,
  dataLayerName: 'ProductSelector',
};
TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Page />
  </QueryClientProvider>
);

export default App;
