import { ProductSelector } from '@sunrun/ui-product-selector';
import React from 'react';
import { useQuery } from 'react-query';

import contentful from './utils/contentful';
import getEnv from './constants/env';
import { PRODUCT_SELECTOR_MAIN_ENTRY } from './constants/contentful';

const Page = () => {
  const { isLoading, error, data } = useQuery('getContent', () =>
    contentful.query({
      'sys.id': PRODUCT_SELECTOR_MAIN_ENTRY,
      include: 5,
    })
  );

  if (error) {
    console.error(error);
    return null;
  }

  if (data && !data?.items[0]?.fields) {
    console.log('Something went wrong with the data!', data);
    return null;
  }

  return (
    <ProductSelector
      env={getEnv(window.location.hostname)}
      content={isLoading ? null : data?.items[0]?.fields}
      isLoading={isLoading}
    />
  );
};

export default Page;
