import { createClient } from 'contentful';

import { ACCESS_TOKEN, HOST, SPACE } from '../constants/contentful';
import getEnv from '../constants/env';

const env = getEnv(window.location.hostname);
const client = createClient({
  accessToken: ACCESS_TOKEN[env],
  host: HOST[env],
  space: SPACE,
});

const contentful = {
  entry: client.getEntry,
  query: client.getEntries,
};

export default contentful;
